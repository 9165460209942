import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import BlogSection from '../components/BlogSection'
import CallToActionSection, {
  LayoutType,
} from '../components/CallToActionSection'
import FeaturedIn from '../components/FeaturedIn'
import InstaMailchimpSection from '../components/InstaMailchimpSection'
import Layout from '../components/Layout'
import RichText from '../components/RichText'
import Seo from '../components/Seo'
import SerifText from '../components/SerifText'
import Testimonial from '../components/Testimonial'
import PageData from '../types/PageData'
import { handleUnsupportedSection } from '../utils'

type Section = {
  _key: string
  _type: string
}

type TCallToActionSection = Section & {
  image: {
    alt: string
    asset: {
      gatsbyImageData: any
    }
  }
  hasBackground: boolean
  heading: string
  layout: LayoutType
  subheading: string
  callToAction: {
    displayText: string
    url: string
  }
}

type FeaturedInSection = Section & {
  image: {
    alt: string
    asset: {
      gatsbyImageData: any
    }
  }
  link: {
    displayText: string
    url: string
  }
}

type TestimonialSection = Section & {
  author: string
  rating: number
  ratingLink?: {
    displayText: string
    url: string
  }
}

type IndexPageQuery = {
  sanityPage: PageData & {
    sections: (TCallToActionSection | FeaturedInSection | TestimonialSection)[]
    _rawSections: {
      _key: string
      text: any[]
    }[]
  }
}

type Props = {
  data: IndexPageQuery
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const hasFeaturedInSection = data.sanityPage.sections.some(
    section => section._type === 'featuredInSection'
  )
  return (
    <Layout
      withHero
      pageData={data?.sanityPage}
      showFeaturedIn={!hasFeaturedInSection}
    >
      <Seo
        title="Cody Calligraphy | Kingston, Ontario Wedding Invitations | Toronto | PEC | Ottawa"
        titleTemplate="%s"
      />
      {data.sanityPage.sections.map(section => {
        // TODO: Abstract tooling to transform raw object to an array of portable text
        const rawSection = data.sanityPage._rawSections.find(
          raw => raw._key === section._key
        )
        let markup = null
        if (section._type === 'landingPageSection') {
          const {
            _key,
            heading,
            subheading,
            image,
            hasBackground,
            layout,
            callToAction,
          } = section as TCallToActionSection
          markup = (
            <CallToActionSection
              key={_key}
              heading={heading}
              subheading={subheading}
              imageSrc={image.asset.gatsbyImageData}
              imageAlt={image.alt}
              hasBackground={hasBackground}
              layout={layout}
              text={rawSection.text}
              callToAction={callToAction}
            />
          )
        } else if (section._type === 'testimonial') {
          const {
            _key,
            author,
            rating,
            ratingLink,
          } = section as TestimonialSection
          markup = (
            <TestimonialWithLayout
              key={_key}
              author={author}
              stars={rating}
              ratingLink={ratingLink}
            >
              <RichText
                value={rawSection.text}
                components={{
                  block: {
                    normal: SerifText,
                  },
                }}
              />
            </TestimonialWithLayout>
          )
        } else if (section._type === 'featuredInSection') {
          const { _key } = section as FeaturedInSection
          markup = <FeaturedIn key={_key} />
        } else {
          handleUnsupportedSection(section._type)
          markup = null
        }
        return markup
      })}
      <BlogSection />
      <InstaMailchimpSection />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    sanityPage(uuid: { eq: "index" }) {
      _rawPageMessage
      _rawSections
      sections {
        ... on SanityFeaturedInSection {
          _key
          _type
        }
        ... on SanityLandingPageSection {
          _key
          _type
          image {
            alt
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                width: 450
              )
            }
          }
          hasBackground
          heading
          layout
          subheading
          callToAction {
            displayText
            url
          }
        }
        ... on SanityTestimonial {
          _key
          _type
          author
          rating
          ratingLink {
            displayText
            url
          }
        }
      }
    }
  }
`

const TestimonialWithLayout = styled(Testimonial)`
  padding: calc(64px - 1em) 0;
`
