import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'
import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

function InstagramGrid({ className }: Props) {
  const {
    allFile: { nodes },
  } = useStaticQuery<InstagramQuery>(graphql`
    {
      allFile(
        filter: {relativePath: {regex: "/instagram-grid-\\d\\.jpg/"}}
        sort: {fields: relativePath, order: ASC}
      ) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, quality: 90, width: 242)
          }
        }
      }
    }
  `)
  return (
    <List className={className}>
      {nodes.map((node, i) => {
        const post = postMetaData[i]
        return (
          <Item key={node.id}>
            <Link
              href={`https://www.instagram.com/p/${post.postId}/`}
              title={post.caption}
            >
              <Image image={getImage(node)} alt="Instagram post" />
            </Link>
          </Item>
        )
      })}
    </List>
  )
}

type InstagramQuery = {
  allFile: {
    nodes: FileNode[]
  }
}

const Link = styled.a``

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
`

const Item = styled.li`
  width: calc(50% - 0.5rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
  &:nth-child(2n) {
    margin-right: 0;
  }
`

const Image = styled(GatsbyImage)<{ image: any }>`
  height: 0;
  width: 100%;
  padding-top: 100%;
`

const postMetaData = [
  {
    postId: 'CMpX9zsBbg8',
    caption: 'There’s nothing like a handwritten envelope ✉️',
  },
  {
    postId: 'CMkNTHQhLB0',
    caption: 'Playing with pretty things 💌 as if this is my job 🤩',
  },
  {
    postId: 'CMdXZ_mhxTS',
    caption: 'The way they look at each other 😭',
  },
  {
    postId: 'CMYTIThBR8c',
    caption:
      'Keep the sunny elopements coming 😎 Keepsake Suites are quickly becoming one of my favourite stationery pieces to create ✨',
  },
]

export default InstagramGrid
