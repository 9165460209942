import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import FlairText from './FlairText'
import LandingPageSection from './LandingPageSection'
import PagePadding from './PagePadding'
import SerifText from './SerifText'

function BlogSection() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "placecards-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 205
            quality: 80
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      allSanityPost(limit: 3, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          title
          publishedAt
          slug {
            current
          }
          mainImage {
            alt
            asset {
              gatsbyImageData(
                width: 205
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)
  const { nodes: posts } = data.allSanityPost
  return (
    <LandingPageSection>
      <Content>
        <Heading as="h2">On the Blog</Heading>
        <Posts>
          {posts.map(post => {
            const {
              id,
              title,
              slug: { current: slug },
              mainImage,
            } = post
            let imgSrc = getImage(data.file)
            if (mainImage?.asset) {
              imgSrc = getImage(mainImage.asset)
            }
            const imgAlt = mainImage ? mainImage.alt : ''
            return (
              <Teaser key={id}>
                <FlexLink to={`/blog/${slug}`}>
                  <Image image={imgSrc} alt={imgAlt} />
                  <Title as="h3" dangerouslySetInnerHTML={{ __html: title }} />
                </FlexLink>
              </Teaser>
            )
          })}
        </Posts>
        <ButtonWithLayout as={Link} to="/blog">
          Read more
        </ButtonWithLayout>
      </Content>
    </LandingPageSection>
  )
}

const flexLinkPadding = 1.5

const Heading = styled(FlairText)`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 48px;
`

const Content = styled(PagePadding)``

const Posts = styled.ul`
  margin-top: ${flexLinkPadding}rem;
`

const Teaser = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: calc(630px + ${2 * flexLinkPadding}rem);
  margin: 0 auto;
`

const FlexLink = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transition: background-color 0.3s;
  padding: ${flexLinkPadding}rem;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.offWhite};
  }
`

const Image = styled(GatsbyImage)<{ image: any; alt: string }>`
  width: 205px;
  height: 154px;
  max-width: 100%;

  @media (max-width: 623px) {
    width: 100px;
    height: 100px;
  }
`

const Title = styled(SerifText)`
  font-size: 1.5rem;
  font-weight: 500;
  flex: 1;
  margin-left: 2.8125rem;

  @media (max-width: 623px) {
    font-size: 1.125rem;
    margin-left: 1.5rem;
  }
`

const ButtonWithLayout = styled(Button)<{ to: string }>`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
`

export default BlogSection
