import React, { useEffect } from 'react'

function FlodeskInlineSubscribe() {
  useEffect(() => {
    window.fd('form', {
      formId: '64861c4ef998ccd67391f6fe',
      containerEl: '#fd-form-64861c4ef998ccd67391f6fe',
    })
  }, [])
  return <div id="fd-form-64861c4ef998ccd67391f6fe"></div>
}

export default FlodeskInlineSubscribe
